import { createI18n } from 'vue-i18n';

const i18n = createI18n({  
  fallbackLocale: 'en',
  messages: {
    'en': require('./locales/en.json'),
    'it': require('./locales/it.json'),
    'fr': require('./locales/fr.json'),
    'de': require('./locales/de.json'),
    'ja': require('./locales/ja.json'),
    'es': require('./locales/es.json'),
    'fil': require('./locales/fil.json'),
    'nl': require('./locales/nl.json'),
    'th': require('./locales/th.json'),
    'tr': require('./locales/tr.json'),
    'zh': require('./locales/zh.json'),
  }
});

export default i18n;