import Vuex, { StoreOptions } from 'vuex';
import { RootState } from './types';
import { surveyModule } from './modules/survey.module';
import { uiModule } from './modules/ui.module';
import { authModule } from './modules/auth.module';
import { errorModule } from './modules/error.module';
import VuexPersistence from 'vuex-persist';

const storeOptions: StoreOptions<RootState> = {
  state: {
    version: '1.0.0'
  },
  modules: {
    auth: authModule,
    survey: surveyModule,
    ui: uiModule,
    error: errorModule
  },
  
  plugins: [new VuexPersistence().plugin]
  
};

export default new Vuex.Store<RootState>(storeOptions);