<template>
  
   <!-- SHOW GLOBAL ERRORS -->
  <Banner v-if="errors != null && errors.length > 0" type="danger">
    <div class="text-center" v-for="(err, eIndex) in errors" :key="'error-' + eIndex">{{ err.error }}</div>
  </Banner>

  <router-view></router-view>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import * as ApiService from '@/utils/api.service';

import Banner from '@/components/commons/Banner.vue';

export default defineComponent({
  name: 'AppComponent',
  components: { Banner },

  computed: {
    ...mapGetters({
      'user': 'auth/getUser',
      'accessToken': 'auth/getToken',
      'errors': 'error/getErrors'
    }),
  },

  mounted() {

    // if exists user in storage
    // init ApiService with access token
    if (this.user && this.accessToken) {
      const language = (navigator.language).substr(0, 2);
      const token = this.accessToken;
      ApiService.init(language, token);
    }
  }

});
</script>