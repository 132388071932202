import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import BootstrapView from '../views/BootstrapView.vue'

const routes: Array<RouteRecordRaw> = [
  
  {
    path: '/',
    name: 'bootstrap',
    component: BootstrapView
  },  
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import (/* webpackChunkName: "home" */ '../views/HomeView.vue')
  },
  
  // *** SURVEY ROUTES ***
  {
    path: '/survey',
    name: 'survey',
    component: () => import(/* webpackChunkName: "survey" */ '../views/survey/SurveyView.vue')
  },
  {
    path: '/survey/sections',
    name: 'surveySections',
    component: () => import(/* webpackChunkName: "surveySections" */ '../views/survey/SectionsView.vue')
  },
  {
    path: '/survey/section',
    name: 'surveySection',
    component: () => import(/* webpackChunkName: "surveySection" */ '../views/survey/SectionView.vue')
  },
  {
    path: '/survey/score',
    name: 'surveyScore',
    component: () => import(/* webpackChunkName: "surveyScore" */ '../views/survey/ScoreView.vue')
  },
  {
    path: '/survey/support',
    name: 'surveySupport',
    component: () => import(/* webpackChunkName: "surveySupport" */ '../views/survey/SupportView.vue')
  },

  // Default route, redirect to Home if no other routes match
  {
    path: '/:catchAll(.*)',
    redirect: '/'
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
