import { Module, MutationTree, ActionTree, GetterTree } from "vuex";
import { RootState, UIState } from "../types";

const state: UIState = {
    reverseNavigation: false,
};

const getters: GetterTree<UIState, RootState> = {
  isReverseNavigation(state) {
    return state.reverseNavigation;
  },
};

const mutations: MutationTree<UIState> = {
  setReverseNavigation(state, value: boolean) {
    state.reverseNavigation = value;
  },
};

const actions: ActionTree<UIState, RootState> = {
};

export const uiModule: Module<UIState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
