import { createApp } from 'vue'
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import '@/assets/style/main.scss';

const app = createApp(App);
app.use(router)
app.use(i18n);
app.use(store);
app.mount('#app');
