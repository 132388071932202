/* eslint-disable @typescript-eslint/no-explicit-any */
import { Module, MutationTree, ActionTree, GetterTree } from "vuex";
import { RootState, ErrorState } from "../types";

const state: ErrorState = {

    errors: [],
    /*
        { 
            key: 'loginError' ,
            error: 'An error occurred',
        }
    */
};

const getters: GetterTree<ErrorState, RootState> = {
    getErrors(state): any[] | null {
        return state.errors;
    },
    getError: (state, getters) => (errorKey: string) => {
        if (!getters.getErrors) return null;
        return getters.getErrors.find((item: any) => {
            return errorKey == item.key
        });

    }
};

const mutations: MutationTree<ErrorState> = {
    setErrors(state, value: []) {
        state.errors = value;
    },
    addError(state, error: any) {
        if (error == null) return;

        delError(state, error.key);
        
        if (!state.errors) state.errors = [];
        state.errors.push(error);
    },

    deleteError(state, errorKey: string) {
        delError(state, errorKey);
    }
};

const actions: ActionTree<ErrorState, RootState> = {};

function delError(state: ErrorState, errorKey: string) {
    const index = state.errors.findIndex((s: any) => { return errorKey == s.key });
    if (index >= 0) state.errors.splice(index, 1);
}

export const errorModule: Module<ErrorState, RootState> = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
