import { Module, MutationTree, ActionTree, GetterTree } from "vuex";
import { RootState, SurveyState } from "../types";
import * as ApiService from '@/utils/api.service';

import EnvironmentManager from "@/environments/EnvironmentManager";
import { Survey, UserSurvey, Section, Group } from '@/store/models/index';

const state: SurveyState = {
  loading: false,

  surveys: null,
  survey: null,
  
  userSurvey: null,
  userSurveys: null,

  /*
    {
      params: {
        "survey": "survey1",
        "identifier": "asdfghjklqwertyuiopzxcvbnmasdfghjkl",
        "sectionIndex": 0,
        "groupIndex": 0,
        "questionIndex": 1
      }
      "answers": {
        "section1": {
          "question1": value,
          "question2": value
        },
        "section2": {
          "question3": value,
          "question4": value
        }
      },
      "vars": {}
    }
  */

  sectionIndex: 0,
  groupIndex: 0,
  questionIndex: 0,
};

const getters: GetterTree<SurveyState, RootState> = {
  isLoading(state) {
    return state.loading;
  },
  getSurveys(state) {
    return state.surveys;
  },
  getSurvey(state) {
    return state.survey;
  },
  getUserSurvey(state) {
    return state.userSurvey;
  },
  getUserSurveys(state) {
    return state.userSurveys;
  },
  getSectionIndex(state) {
    return state.sectionIndex;
  },
  getGroupIndex(state) {
    return state.groupIndex;
  },
  getQuestionIndex(state) {
    // returns the question index in current group
    return state.questionIndex;
  },
  getQuestionIndexInSection(state, getters) {
    // returns the question index relative to whole section
    const section = getters.getCurrentSection;
    if (section == null || section.groups == null || section.groups.length == 0) return 0;

    let count = 0;
    const currentGroupIndex = getters.getGroupIndex;
    section.groups.forEach((group: Group, index: number) => {
      if (group.questions != null && group.questions.length > 0) {
        if (index < currentGroupIndex) {
          count += group.questions.length;
        } else if (index == currentGroupIndex) {
          const currentQuestionIndex = getters.getQuestionIndex;
          count += currentQuestionIndex;
        }
      }
    });
    return count;
  },

  getCurrentSection(state) {
    if (state.survey != null && state.survey.sections != null)
      return state.survey.sections[state.sectionIndex];
    else return null;
  },
  getCurrentGroup(state, getters) {
    const section = getters.getCurrentSection;
    if (section != null && section.groups != null) return section.groups[state.groupIndex];
    else return null;
  },
  getCurrentQuestion(state, getters) {
    const group = getters.getCurrentGroup;
    if (group != null && group.questions != null)
      return group.questions[state.questionIndex];
    else return null;
  },

  // **** COUNT ***
  /// Returns the question count of the currently selected group 
  countQuestionsInSurvey(state, getters): number {
    const survey = getters.getSurvey;
    let count = 0;
    if (survey != null && survey.sections != null) {
      survey.sections.forEach((section: Section) => {
        if (section != null && section.groups != null) {
          section.groups.forEach((group: Group) => {
            if (group.questions != null) count += group.questions.length;
          });
        }
      });
    }
    return count;
  },

  /// Returns the section count of the current survey
  countSectionsInSurvey(state, getters): number {
    const survey = getters.getSurvey;
    if (survey != null && survey.sections != null)
      return survey.sections.length;
    else return 0;
  },

  /// Returns the groups count of the current section
  countGroupsInSection(state, getters): number {
    const survey = getters.getSurvey;
    if (survey == null || survey.sections == null) return 0;

    const section = getters.getCurrentSection;
    if (section != null && section.groups != null) return section.groups.length;
    else return 0;
  },

  /// Returns the question count of the currently selected group 
  countQuestionsInGroup(state, getters): number {
    const group = getters.getCurrentGroup;
    if (group != null && group.questions != null) return group.questions.length;
    else return 0;
  },

  /// Returns the questions count of the current section
  countQuestionsInSection(state, getters): number {
    let count = 0;
    const survey = getters.getSurvey;
    if (survey == null || survey.sections == null) return count;

    const section = getters.getCurrentSection;
    if (section != null && section.groups != null) {
      section.groups.forEach((group: Group) => {
        if (group.questions != null) count += group.questions.length;
      });
    }
    return count;
  },

  // Return true if the fsurvey end
  isSurveyLastStep(state, getters): boolean {
    let lastStep = (getters.getSectionIndex + 1 == getters.countSectionsInSurvey) && (getters.getGroupIndex + 1 == getters.countGroupsInSection);
    if (getters.countQuestionsInGroup > 0) {
      lastStep = lastStep && (getters.getQuestionIndex + 1 == getters.countQuestionsInGroup);
    }
    return lastStep;
  },

  // Return true if the survey first step
  isSurveyFirstStep(state, getters): boolean {
    return getters.getQuestionIndex <= 0
      && getters.getGroupIndex <= 0
      && getters.getSectionIndex <= 0;
  },

};

const mutations: MutationTree<SurveyState> = {
  setLoading(state, value: boolean) {
    state.loading = value;
  },
  setSurveys(state, value: Survey[]) {
    state.surveys = value;
  },
  setSurvey(state, value: Survey) {
    state.survey = value;
  },
  setUserSurvey(state, value: UserSurvey) {
    state.userSurvey = value;
  },
  setUserSurveys(state, value: UserSurvey[]) {
    state.userSurveys = value;
  },
  setSectionIndex(state, value: number) {
    state.sectionIndex = value;
  },
  setGroupIndex(state, value: number) {
    state.groupIndex = value;
  },
  setQuestionIndex(state, value: number) {
    state.questionIndex = value;
  },
};

const actions: ActionTree<SurveyState, RootState> = {

  clear(context) {
    context.commit("setSurveys", null);
    context.commit("setSurvey", null);
    context.commit("setUserSurvey", null);
    context.commit("setSectionIndex", 0);
    context.commit("setGroupIndex", 0);
    context.commit("setQuestionIndex", 0);
    context.commit('ui/setReverseNavigation', false, { root: true });
  },

  resetFlow(context) {
    context.commit("setSectionIndex", 0);
    context.commit("setGroupIndex", 0);
    context.commit("setQuestionIndex", 0);
    context.commit('ui/setReverseNavigation', false, { root: true });
  },

  // store the given value inside the current section
  // relative to the current question
  storeAnswer(context, value: any) {
    const currentSection = context.getters.getCurrentSection;
    const currentQuestion = context.getters.getCurrentQuestion;
    if (currentSection == null || currentQuestion == null) return;

    const userSurvey = context.getters.getUserSurvey;
    if (userSurvey == null) throw Error("Invalid user survey");
    
    if (userSurvey.sections == null) userSurvey.sections = [];
    
    const sectionIndex = context.getters.getSectionIndex;
    const section = userSurvey.sections[sectionIndex];
    // if section does not exists add into userSurvey object
    if (section == null) {
      userSurvey.sections[sectionIndex] = {
        "id": currentSection.id,
        "answers": {}
      };
    }
    
    // override answer value and store again
    userSurvey.sections[sectionIndex].answers[currentQuestion.id] = value;
    context.commit("setUserSurvey", userSurvey);
  },

  // **** NAVIGATION ****
  prev(context) {

    context.commit('ui/setReverseNavigation', true, { root: true });
    if (context.state.questionIndex > 0) {
      // Go to previous question in the same group
      context.commit("setQuestionIndex", context.state.questionIndex - 1);
    } else {
      if (context.state.groupIndex > 0) {
        // Go to previous group in the same section
        // Go to last question in group
        context.commit("setGroupIndex", context.state.groupIndex - 1);
        const lastQuestionIndexInGroup = context.getters.countQuestionsInGroup - 1;
        context.commit("setQuestionIndex", lastQuestionIndexInGroup);
      } else if (context.state.sectionIndex > 0) {
        // Go to previous section
        // Go to last group in section
        // Go to last question in group
        context.commit("setSectionIndex", context.state.sectionIndex - 1);
        const lastGroupIndexInSection = context.getters.countGroupsInSection - 1;
        context.commit("setGroupIndex", lastGroupIndexInSection);
        const lastQuestionIndexInLastGroup = context.getters.countQuestionsInGroup - 1;
        context.commit("setQuestionIndex", lastQuestionIndexInLastGroup);
      } else {
        // FIRST SECTION / GROUP / QUESTION REACHED
      }
    }
  },

  next(context) {
    const sectionsInSurvey = context.getters.countSectionsInSurvey;
    const groupsInSection = context.getters.countGroupsInSection;
    const questionsInGroup = context.getters.countQuestionsInGroup;

    context.commit('ui/setReverseNavigation', false, { root: true });
    if (context.state.questionIndex < questionsInGroup - 1) {
      // go to next question in the same group
      context.commit("setQuestionIndex", context.state.questionIndex + 1);
    } else {
      if (context.state.groupIndex < groupsInSection - 1) {
        // go to next group in the same section
        // go to first question in group
        context.commit("setGroupIndex", context.state.groupIndex + 1);
        context.commit("setQuestionIndex", 0);
      } else if (context.state.sectionIndex < sectionsInSurvey - 1) {
        // go to next section
        // go to first group
        // go to first question
        context.commit("setSectionIndex", context.state.sectionIndex + 1);
        context.commit("setGroupIndex", 0);
        context.commit("setQuestionIndex", 0);
      } else {
        // LAST SECTION / GROUP / QUESTION REACHED
      }
    }
  },


  // ******************** API CALLS ****************//

  // Returns all surveys
  fetchSurveyList(context): Promise<unknown> {

    const manager = new EnvironmentManager();
    const url = manager.phrUrl() + EnvironmentManager.URL_SURVEY_LIST;

    context.commit("setLoading", true);
    return ApiService.get(url).then((response) => {
      
      if (response && response.data && response.data.data && response.data.data.surveys) {
        const surveys = response.data.data.surveys;
        context.commit("setSurveys", surveys);
        return surveys;
      }
      return null;
    }).catch((error) => {
      console.error(error);
      ApiService.throwError(error);
    }).finally(() => {
      context.commit("setLoading", false);
    });
  },

  // Returns the survey structure
  // needed to build the questions flow
  fetchSurveyDetail(context, surveyId: string): Promise<unknown> {

    const manager = new EnvironmentManager();
    let url = manager.phrUrl() + EnvironmentManager.URL_SURVEY_DETAIL;
    url = url.replace("{{id}}", surveyId);

    context.commit("setLoading", true);
    return ApiService.get(url).then((response) => {

      if (response && response.data && response.data.data && response.data.data) {
        const survey = response.data.data;
        return survey;
      }

      throw new Error("Survey not found: " + surveyId);

    }).catch((error) => {
      console.error(error);
      ApiService.throwError(error);
    }).finally(() => {
      context.commit("setLoading", false);
    });
  },

  // Call the SEARCH api to retrieve surveys history
  // data contains both open and closed surveys
  fetchSurveySearch(context) {

    const manager = new EnvironmentManager();
    const url = manager.phrUrl() + EnvironmentManager.URL_SURVEY_SEARCH;

    context.commit("setLoading", true);
    return ApiService.post(url).then((response) => {

      if (response && response.data && (response.data.status == 0 || response.data.status == 200) && response.data.data) {
        const list = response.data.data;
        context.commit("setUserSurveys", list);
        return list;
      }

      ApiService.throwError(response);

    }).catch((error) => {
      console.error(error);
      ApiService.throwError(error);
    }).finally(() => {
      context.commit("setLoading", false);
    });
  },

  /// Start a new instance of survey
  // returns the created survey with identifier
  startSurvey(context, surveyId: string): Promise<unknown> {
    
    const manager = new EnvironmentManager();
    let url = manager.phrUrl() + EnvironmentManager.URL_SURVEY_START;
    url = url.replace("{{id}}", surveyId);

    context.commit("setLoading", true);
    return ApiService.post(url).then((response) => {

      if (response && response.data && (response.data.status == 0 || response.data.status == 200) && response.data.data.identifier) {
        const survey = response.data.data;
        context.commit("setUserSurvey", survey);
        return survey;
      }

      ApiService.throwError(response);

    }).catch((error) => {
      console.error(error);
      ApiService.throwError(error);
    }).finally(() => {
      context.commit("setLoading", false);
    });
  },

  // Reset a previously created survey
  // Also delete all compiled answers
  resetSurvey(context, identifier: string): Promise<unknown> {
    
    const manager = new EnvironmentManager();
    let url = manager.phrUrl() + EnvironmentManager.URL_SURVEY_RESET;
    url = url.replace("{{identifier}}", identifier);
    context.commit("setLoading", true);
    return ApiService.put(url).then((response) => {
      
      if (response && response.data && (response.data.status == 0 || response.data.status == 200) && response.data.data.identifier) {
        const survey = response.data.data;
        context.commit("setUserSurvey", survey);
        return survey;
      }

      ApiService.throwError(response);

    }).catch((error) => {
      console.error(error);
      ApiService.throwError(error);
    }).finally(() => {
      context.commit("setLoading", false);
    });
  },

  // Close the survey
  // no more edit operations are permitted
  finishSurvey(context, identifier: string): Promise<unknown> {
    
    const manager = new EnvironmentManager();
    let url = manager.phrUrl() + EnvironmentManager.URL_SURVEY_FINISH;
    url = url.replace("{{identifier}}", identifier);
    context.commit("setLoading", true);
    return ApiService.put(url).then((response) => {
      if (response && response.data && (response.data.status == 0 || response.data.status == 200)) {
        return true;
      }
      ApiService.throwError(response);

    }).catch((error) => {
      console.error(error);
      ApiService.throwError(error);
    }).finally(() => {
      context.commit("setLoading", false);
    });
  },

  // Save all questions in current section
  saveCurrentSection(context): Promise<unknown> {

    const section = context.getters.getCurrentSection;
    if (section == null) throw new Error("Invalid section");

    // throw error if userSurvey doesn't exists or identifier is null
    const userSurvey = context.getters.getUserSurvey;
    if (userSurvey == null || userSurvey.identifier == null) throw new Error("Invalid user survey or indentifier not found");
      
    // throw error if userSurvey has no sections
    if (userSurvey.sections == null || userSurvey.sections.length == 0) throw new Error("userSurvey section " + section.id + " not found");
    
    // skip if userSurvey section has no asnwers
    const currentSectionIndex = context.getters.getSectionIndex;
    if (userSurvey.sections[currentSectionIndex] == null || userSurvey.sections[currentSectionIndex].answers == null || userSurvey.sections[currentSectionIndex].answers.length == 0) {
      return new Promise((resolve) => {
        resolve(true);
      })
    }
      
      
    context.commit("setLoading", true);

    const answers = userSurvey.sections[currentSectionIndex].answers
    const manager = new EnvironmentManager();
    let url = manager.phrUrl() + EnvironmentManager.URL_SURVEY_SECTION_SAVE;
    url = url.replace("{{identifier}}", userSurvey.identifier);

    const body = { "answers": answers };
    return ApiService.post(url, body).then((response) => {

      if (response && response.data && (response.data.status == 200 || response.data.status == 0) && response.data.data && response.data.data.identifier) {
        return true;
      }

      ApiService.throwError(response);
      
    }).catch((error) => {
      console.error(error);
      ApiService.throwError(error);
    }).finally(() => {
      context.commit("setLoading", false);
    });
  },
};

export const surveyModule: Module<SurveyState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
