import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "banner-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.classNames)
      }, [
        _createElementVNode("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "default")
        ]),
        (_ctx.showCloseButton)
          ? (_openBlock(), _createElementBlock("i", {
              key: 0,
              class: "m-icon close-icon",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeBanner && _ctx.closeBanner(...args)))
            }, "close"))
          : _createCommentVNode("", true)
      ], 2))
    : _createCommentVNode("", true)
}