<template>
    <div v-if="show" :class="classNames">
        <div class="banner-content">
            <slot></slot>
        </div>
        <i v-if="showCloseButton" class="m-icon close-icon" @click="closeBanner">close</i>
    </div>
</template>
<script lang="ts">

import { defineComponent } from 'vue';
import '@/assets/style/components/banner.scss';
import '@/assets/style/animate.scss';

export default defineComponent({

    name: "BannerComponent",
    props: {

        type: {
            type: String,
            default: 'primary',
            required: false
        },

        autoclose: {
            type: Boolean,
            default: true,
        },

        autocloseTimeout: {
            type: Number,
            default: 6000,
        },

        showCloseButton: {
            type: Boolean,
            default: true,
        }
    },

    computed: {
        classNames() {
            let classNames = ['banner animated'];
            classNames.push(this.type);

            if (!this.closing) classNames.push('fadeInDown');
            else classNames.push('fadeOutUp');

            return classNames;
        }
    },

    data() {
        return {
            closing: false,
            show: true
        };
    },

    mounted() {
        if (this.autoclose) {
            this.show = true;
            setTimeout(() => {
                this.closeBanner();
            }, this.autocloseTimeout);
        }
    },

    methods: {
        closeBanner() {
            this.closing = true;
            setTimeout(() => {
                this.show = false;
                this.$emit('close');
            }, 1000);
        }
    }

});
</script>