export default class EnvironmentManager {

    static URL_AUTH_LOGIN = "/api/auth2/token/password";
    static URL_AUTH_LOGOUT = "/api/auth2/user/signout";
    static URL_AUTH_USER_INFO = "/api/auth2/user/info";
    static URL_AUTH_TOKEN_REFRESH = "/api/auth2/token/refresh";
    
    static URL_SURVEY_LIST = "/api/survey/template/list";
    static URL_SURVEY_SEARCH = "/api/survey/template/search";
    static URL_SURVEY_DETAIL = "/api/survey/template/{{id}}/detail";
    static URL_SURVEY_START = "/api/survey/template/{{id}}/start";
    static URL_SURVEY_RESET = "/api/survey/template/{{identifier}}/reset";
    static URL_SURVEY_FINISH = "/api/survey/template/{{identifier}}/finish";
    
    static URL_SURVEY_SECTION_GET = "/api/survey/section/{{identifier}}/get";
    static URL_SURVEY_SECTION_SAVE = "/api/survey/section/{{identifier}}/save";
    
    private env: any;

    constructor() {
        this.env = require('./env.dev.json');
    }

    appClientId(): string {
        return this.env.appClientId;
    }

    appUrl(): string {
        return this.env.appUrl;
    }

    authUrl(): string {
        return this.env.authUrl;
    }

    phrUrl(): string {
        return this.env.phrUrl;
    }

}