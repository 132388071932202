import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Banner = _resolveComponent("Banner")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.errors != null && _ctx.errors.length > 0)
      ? (_openBlock(), _createBlock(_component_Banner, {
          key: 0,
          type: "danger"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (err, eIndex) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "text-center",
                key: 'error-' + eIndex
              }, _toDisplayString(err.error), 1))
            }), 128))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view)
  ], 64))
}